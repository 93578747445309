
import MyTopicsHeader from "@/components/topic/MyTopicsHeader.vue";
import { defineComponent } from "@vue/runtime-core";
import MyTopicCard from "@/components/topic/MyTopicCard.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { TopicList } from "@toppick/common/build/interfaces";
import { countPages, parsePage } from "@/utils/ui";
import { ProfilePageTab } from "@/interfaces/ui";
import { getUserMyTopics, getUserTopicsCount } from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

const RESULTS_PER_PAGE = 5;
const RANDOM_TOPICS_NUMBER = 15;

export default defineComponent({
  components: { MyTopicsHeader, MyTopicCard, Pagination },
  props: {},
  data() {
    return {
      name: "",
      topics: [] as TopicList[],
      listTitle: "My Topics",
      listDescription: "Our daily topic selection for you.",
      topicCount: 0,
      isRandom: false,
      isPageLoading: true,
    };
  },
  methods: {
    async loadData() {
      const skip = (this.currentPage - 1) * RESULTS_PER_PAGE;
      this.topics = await getUserMyTopics(
        await getAuthToken(),
        this.$store.getters.getUserID,
        {
          limit: RESULTS_PER_PAGE,
          skip,
          random: this.isRandom,
        }
      );
    },

    setPage(page: number) {
      this.$router.replace({
        query: { page },
      });
    },
    goProfile() {
      this.$router.push({
        name: "Profile",
        params: {
          tab: ProfilePageTab.INTERESTS,
        },
      });
    },
  },
  computed: {
    totalPages(): number {
      return countPages(this.topicCount, RESULTS_PER_PAGE);
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
  },

  mounted: async function () {
    const userTopicsCount = await getUserTopicsCount(
      await getAuthToken(),
      this.$store.getters.getUserID
    );
    if (userTopicsCount > 0) {
      this.topicCount = userTopicsCount;
    } else {
      this.topicCount = RANDOM_TOPICS_NUMBER;
      this.isRandom = true;
    }

    this.$watch(
      () => ({
        page: this.currentPage,
      }),
      async () => {
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
