import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    style: _normalizeStyle({ color: _ctx.active ? 'orange' : 'black' })
  }, [
    (_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.active ? _ctx.icon_active : _ctx.icon_inactive,
          alt: "",
          class: _normalizeClass({ 'active-tab': _ctx.active })
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createTextVNode(_toDisplayString(_ctx.title), 1)
  ], 4))
}