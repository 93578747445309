
import { TopicPageTab } from "@/interfaces/ui";
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["onDelete"],
  props: {
    title: String,
    description: String,
    image: String,
    slug: String,
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      TopicPageTab,
    };
  },
  methods: {
    onDelete() {
      this.$emit("onDelete");
    },
  },
});
