import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "body_wrap about_page" }
const _hoisted_2 = {
  key: 0,
  class: "topic_wrapper space"
}
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-8 mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_topic_header = _resolveComponent("my-topic-header")!
  const _component_side_menu = _resolveComponent("side-menu")!
  const _component_my_topics = _resolveComponent("my-topics")!
  const _component_viewed_topics = _resolveComponent("viewed-topics")!
  const _component_edited_topics = _resolveComponent("edited-topics")!
  const _component_user_topics_list = _resolveComponent("user-topics-list")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_my_topic_header),
      (_ctx.currentTab)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_side_menu, {
                  currentTab: _ctx.currentTab,
                  onOnChangeTab: _ctx.setTab
                }, null, 8, ["currentTab", "onOnChangeTab"]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.currentTab === _ctx.TopicsPageTab.MY_TOPICS)
                    ? (_openBlock(), _createBlock(_component_my_topics, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_ctx.currentTab === _ctx.TopicsPageTab.VIEWED)
                    ? (_openBlock(), _createBlock(_component_viewed_topics, { key: 1 }))
                    : _createCommentVNode("", true),
                  (_ctx.currentTab === _ctx.TopicsPageTab.EDITED)
                    ? (_openBlock(), _createBlock(_component_edited_topics, { key: 2 }))
                    : _createCommentVNode("", true),
                  (_ctx.currentTab === _ctx.TopicsPageTab.USER_LIST)
                    ? (_openBlock(), _createBlock(_component_user_topics_list, { key: 3 }))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ], 64))
}