
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    title: String,
    description: String,
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
