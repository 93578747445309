
import MyTopicsHeader from "@/components/topic/MyTopicsHeader.vue";
import { defineComponent } from "@vue/runtime-core";
import MyTopicCard from "@/components/topic/MyTopicCard.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { countPages, parsePage } from "@/utils/ui";
import { StatTopicAction, TopicStat } from "@toppick/common/build/interfaces";
import { getTopicsStats } from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

const RESULTS_PER_PAGE = 5;

export default defineComponent({
  components: { MyTopicsHeader, MyTopicCard, Pagination },
  data() {
    return {
      name: "",
      topics: [] as TopicStat[],
      listTitle: "Edited Topics",
      listDescription: "Here are the topics you've edited.",
      totalCount: 0,
      hasLoaded: false,
    };
  },
  methods: {
    async loadData() {
      const skip = (this.currentPage - 1) * RESULTS_PER_PAGE;
      const res = await getTopicsStats(await getAuthToken(), {
        skip,
        limit: RESULTS_PER_PAGE,
        user_id: this.$store.getters.getUserID,
        action: StatTopicAction.Update,
        include_total_count: !this.hasLoaded,
      });
      this.topics = res.topics;
      if (!this.hasLoaded) {
        this.totalCount = res.totalCount!;
      }
    },
    setPage(page: number) {
      this.$router.replace({
        query: { page },
      });
    },
  },
  computed: {
    totalPages(): number {
      return countPages(this.totalCount, RESULTS_PER_PAGE);
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
  },
  mounted: function () {
    this.$watch(
      () => ({
        page: this.currentPage,
      }),
      async () => {
        try {
          await this.loadData();
          this.hasLoaded = true;
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
