import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "my_topics_lists" }
const _hoisted_2 = { class: "my_list_inner" }
const _hoisted_3 = { class: "my_topics_outer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_my_topics_header = _resolveComponent("my-topics-header")!
  const _component_my_topic_card = _resolveComponent("my-topic-card")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_my_topics_header, {
        title: _ctx.listTitle,
        "onUpdate:title": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.listTitle) = $event)),
        description: _ctx.listDescription,
        "onUpdate:description": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listDescription) = $event)),
        disabled: true
      }, null, 8, ["title", "description"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topics, (topic) => {
          return (_openBlock(), _createBlock(_component_my_topic_card, {
            key: topic.id,
            description: topic.topics.description,
            image: topic.topics.image,
            title: topic.topics.title,
            slug: topic.topics.slug,
            disabled: true
          }, null, 8, ["description", "image", "title", "slug"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_pagination, {
      currentPage: _ctx.currentPage,
      totalPages: _ctx.totalPages,
      onOnChangePage: _ctx.setPage
    }, null, 8, ["currentPage", "totalPages", "onOnChangePage"])
  ]))
}