
import MyTopicsHeader from "@/components/topic/MyTopicsHeader.vue";
import { TopicFeatured, TopicList } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import MyTopicCard from "@/components/topic/MyTopicCard.vue";
import Pagination from "@/components/ui/Pagination.vue";
import { countPages, parseId, parsePage } from "@/utils/ui";
import {
  deleteTopicList,
  getTopicListDetails,
  removeTopicFromUserList,
  updateTopicList,
} from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

const RESULTS_PER_PAGE = 6;

export default defineComponent({
  emits: [],
  components: { MyTopicsHeader, MyTopicCard, Pagination },
  data() {
    return {
      topics: [] as TopicFeatured[],
      currentList: null as TopicList | null,
    };
  },
  methods: {
    setPage(page: number) {
      this.$router.replace({
        query: { page },
      });
    },

    async onSaveListInfo() {
      try {
        if (this.currentList) {
          await updateTopicList(
            await getAuthToken(),
            this.$store.getters.getUserID,
            this.currentList.id,
            {
              title: this.currentList.title!,
              description: this.currentList.description,
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onRemoveTopicFromList(id: number) {
      try {
        await removeTopicFromUserList(
          await getAuthToken(),
          this.$store.getters.getUserID,
          this.currentList!.id,
          id
        );
        const newListTopics = this.currentList!.topic_list_topics!.filter(
          (topic) => topic.topics.id !== id
        );
        this.currentList!.topic_list_topics = newListTopics;
      } catch (error) {
        console.log(error);
      }
    },
    async onDeleteList() {
      try {
        await deleteTopicList(
          await getAuthToken(),
          this.$store.getters.getUserID,
          this.currentList!.id
        );
        this.eventBus.emit("onDeleteUserList", {
          id: this.currentList!.id,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    totalPages() {
      if (!this.currentList) return 0;
      return countPages(
        this.currentList.topic_list_topics.length,
        RESULTS_PER_PAGE
      );
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
    currentListId(): number {
      return parseId(this.$route.query.id);
    },
  },

  watch: {
    "currentList.title": function (text: string) {
      if (this.currentList) {
        this.eventBus.emit("onChangeUserListTitle", {
          text,
          id: this.currentList.id,
        });
        this.onSaveListInfo();
      }
    },
    "currentList.description": function () {
      this.onSaveListInfo();
    },
  },

  mounted: function () {
    this.$watch(
      () => ({
        page: this.currentPage,
        id: this.currentListId,
      }),
      async () => {
        try {
          this.currentList = null;
          const listId = parseId(this.$route.query.id);
          if (listId > 0) {
            try {
              this.currentList = await getTopicListDetails(
                await getAuthToken(),
                this.$store.getters.getUserID,
                { id: listId }
              );
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
      { immediate: true }
    );
  },
});
