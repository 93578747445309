
import { TopicsPageTab } from "@/interfaces/ui";
import { TopicList } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import MyTopicHeader from "../../components/my_topic/MyTopicHeader.vue";
import SideMenu from "../../components/my_topic/SideMenu.vue";
import MyTopics from "./MyTopics.vue";
import ViewedTopics from "./ViewedTopics.vue";
import UserTopicsList from "./UserTopicsList.vue";
import EditedTopics from "./EditedTopics.vue";
import { isValidTab } from "@/utils/ui";
import Loader from "@/components/ui/Loader.vue";
import "./styles.css";
import { getUserTopicsInfo } from "@toppick/common/build/api";

export default defineComponent({
  components: {
    SideMenu,
    MyTopicHeader,
    MyTopics,
    ViewedTopics,
    UserTopicsList,
    EditedTopics,
    Loader,
  },
  data() {
    return {
      TopicsPageTab,
      isLoading: true,
      lastViewedCounter: 0,
      lastEditedCounter: 0,
      userTopicLists: [] as TopicList[],
    };
  },
  methods: {
    setTab(tab: TopicsPageTab) {
      this.$router.replace({
        params: { tab },
        query: { page: 1 },
      });
    },
  },

  computed: {
    currentTab(): string {
      return this.$route.params.tab as string;
    },
  },

  async mounted() {
    try {
      if (!isValidTab(TopicsPageTab, this.$route.params.tab)) {
        this.setTab(TopicsPageTab.MY_TOPICS);
      }
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
});
