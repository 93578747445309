
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { TopicsPageTab } from "@/interfaces/ui";
import TabButton from "./TabButton.vue";
import { parseId } from "@/utils/ui";
import { TopicListFeatured } from "@toppick/common/build/interfaces";
import { createTopicList, getTopicLists } from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  emits: ["onChangeTab"],
  components: { TabButton },
  props: {
    currentTab: {
      type: String as PropType<TopicsPageTab>,
      required: true,
    },
  },
  data() {
    return {
      TopicsPageTab,
      userTopicLists: [] as TopicListFeatured[],
    };
  },
  methods: {
    onChangeTab(tab: TopicsPageTab) {
      this.$emit("onChangeTab", tab);
      this.userTopicLists.forEach((list) => (list.selected = false));
    },
    async onCreateList() {
      try {
        const createdList = await createTopicList(
          await getAuthToken(),
          this.$store.getters.getUserID,
          { title: "New List" }
        );
        this.userTopicLists.forEach((list) => (list.selected = false));
        this.userTopicLists = [
          ...this.userTopicLists,
          { ...createdList, selected: true },
        ];
        this.onSelectUserList(createdList.id);
      } catch (error) {
        console.log(error);
      }
    },

    async onSelectUserList(id: number | null) {
      this.userTopicLists.forEach((list) => {
        if (list.id === id) list.selected = true;
        else list.selected = false;
      });
      this.$router.push({
        params: { ...this.$route.params, tab: TopicsPageTab.USER_LIST },
        query: { ...this.$route.query, id },
      });
    },

    /* Will be called from another component */
    updateUserListTitle({ id, text }: { id: number; text: string }) {
      const newList = [...this.userTopicLists];
      const index = newList.findIndex((list) => list.id === id);
      if (index >= 0) {
        newList[index].title = text ? text : "New List";
      }
      this.userTopicLists = newList;
    },

    /* Will be called from another component */
    deleteUserList({ id }: { id: number }) {
      const index = this.userTopicLists.findIndex((list) => list.id === id);
      const newList = this.userTopicLists.filter((list) => list.id !== id);
      if (newList.length > 0) {
        this.onSelectUserList(newList[Math.max(0, index - 1)].id);
      } else {
        this.onChangeTab(TopicsPageTab.MY_TOPICS);
      }
      this.userTopicLists = newList;
    },
  },

  async mounted() {
    this.eventBus.on("onChangeUserListTitle", this.updateUserListTitle);
    this.eventBus.on("onDeleteUserList", this.deleteUserList);

    try {
      const userTopicLists = await getTopicLists(
        await getAuthToken(),
        this.$store.getters.getUserID
      );
      this.userTopicLists = userTopicLists;
      const listId = parseId(this.$route.query.id);
      if (listId > 0) this.onSelectUserList(listId);
    } catch (error) {
      console.log(error);
    }
  },
});
