<template>
  <section
    :style="{
      'background-image':
        'url(https://images.unsplash.com/photo-1497215842964-222b430dc094?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDF8fHdvcmt8ZW58MHx8MHx8&w=1000&q=80)',
    }"
    class="space cmn_hero_banner inner_cmn_hero text-center blog_sec"
  >
    <div class="container">
      <h1>Topics</h1>
      <div class="Breadcrums">
        <router-link class="cm-color" :to="{ name: 'Home' }">Home</router-link>
        <img :src="RightArrow" alt="" class="mx-2" />
        <router-link
          class="active text-white"
          :to="{ name: 'MyTopics', params: { tab: 'me' } }"
          >Topics</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      RightArrow: require("@/assets/images/right-arrow-black.svg"),
    };
  },
};
</script>
